import { iframeResizer } from "iframe-resizer";

window.iframeResizer = window.iframeResizer || iframeResizer;

// eslint-disable-next-line no-console
const clog = console.log;
const isDevelopment = [
  "coorow.vm1.lateralaspect.com.au",
  "coorow.local"
].includes(window.location.host);

const iframes = document.querySelectorAll(".js-iframe-resizer");

for (const iframe of iframes) {
  if (window.iframeResizer !== undefined && iframe.src?.length === 0) {
    iframe.src = iframe.dataset?.iframeSrc;
    window.iframeResizer({
      autoResize: true,
      bodyBackground: "#fefcf6",
      bodyMargin: 0,
      bodyPadding: 0,
      checkOrigin: false,
      inPageLinks: false,
      onMessage(messageData) {
        if (isDevelopment) {
          clog(messageData);
        }
      },
      scrolling: true, // was true
      warningTimeout: 5000
    }, iframe);
  }
}
