import * as bootstrap from "bootstrap";

// eslint-disable-next-line no-console
const clog = console.log;

// Import jQuery into the global window object as $
window.$ = window.$ || $;
// Import Bootstrap into the global window object as bootstrap
window.bootstrap = window.bootstrap || bootstrap;

// List of hostnames that will be recognised as either dev or staging
const developmentHosts = [
  "localhost",
  ".local",
  "127.0.0.1"
];
const stagingHosts = [
  "vm1.lateralaspect.com.au"
];

const isDevelopment = [...developmentHosts, ...stagingHosts].some(host => window.location.host.includes(host));
const isStaging = stagingHosts.some(host => window.location.host.includes(host));
const isProduction = !(isDevelopment || isStaging);

const wpadminbar = document.querySelector("#wpadminbar");
const adminbarBgColors = {
  development: "#3b873e",
  production: "#e91e63",
  staging: "#ff9800"
};

// eslint-disable-next-line no-nested-ternary
const adminBgColor = isProduction
  ? adminbarBgColors.production
  : (isStaging ? adminbarBgColors.staging : adminbarBgColors.development);
const htmlElem = document.documentElement;
const scriptElem = document.querySelector("#_bs-wpadmin-scripts-js");
const userTag = scriptElem ? scriptElem.dataset?.user : "client";

document.body.classList.add(`user-${userTag}`);

const setupGlobalOpts = () => {
  const opts = {
    applyBodyStyles: function() {
      if (document && document.querySelectorAll(".medium-editor-element").length > 0) {
        htmlElem.classList.add("wp-admin-has-medium-editor");
      }

      if (!htmlElem.classList.contains("wp-toolbar")) {
        htmlElem.style.marginTop = "0 !important";
        htmlElem.classList.add("wp-frontend");
      }

      return undefined;
    },
    bsCollapses: null,
    collapses: [],
    isDevelopment,
    userConfig: {
      adminBgColor,
      adminbarBgColors
    }
  };

  return opts;
};

window.BASELINES_APP = window.BASELINES_APP || setupGlobalOpts();

// Avoid duplicating styles if this is somehow run twice
if (wpadminbar && !document.querySelector("#adminStyleElem")) {
  // Add <style id="adminStyleElem"> to <head> for styling admin bar
  const adminStyleElem = document.createElement("style");

  adminStyleElem.id = "adminStyleElem";
  adminStyleElem.innerHTML = `
    .wp-admin #wpadminbar,
    .logged-in #wpadminbar {
      background: ${adminBgColor} !important;
    }
  `;

  document.head.append(adminStyleElem);
}

window.localStorage.setItem("userConfig", JSON.stringify(window.BASELINES_APP.userConfig || {}));

window.BASELINES_APP.applyBodyStyles();

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const ignoreTooltipList = [...tooltipTriggerList].map(
  tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl)
);

const collapseParents = document.querySelectorAll(".has-collapse");
const collapseElems = document.querySelectorAll(".has-collapse>.collapse");

for (const parentElem of collapseParents) {
  const parentClass = [...parentElem.classList].find(item => item.includes("item-"));
  const linkElem = [...parentElem.children].find(item => item.tagName === "A");
  const collapseElem = [...parentElem.children].find(item => item.tagName === "UL");

  if (linkElem && parentClass) {
    linkElem.dataset.bsToggle = "collapse";
    linkElem.dataset.bsTarget = `#${parentClass}`;
    linkElem.ariaExpanded = "false";
    linkElem.ariaControls = `${parentClass}`;
    linkElem.type = "button";
  }

  if (collapseElem && parentClass) {
    collapseElem.classList.add("collapse");
    collapseElem.id = parentClass;
    window.BASELINES_APP.collapses.push(collapseElem);
  }

  // if (window.BASELINES_APP.isDevelopment) {
  //   clog({ collapseElem, linkElem, parentClass });
  // }
}

const navbarUpper = document.querySelector(".navbar-upper");
window.BASELINES_APP.navbarToggler = document.querySelector("#navbarToggler");

window.BASELINES_APP.bsCollapses = [...collapseElems].map(
  collapseEl => new bootstrap.Collapse(collapseEl, {
    parent: navbarUpper,
    toggle: false
  })
);

for (const bsCollapse of document.querySelectorAll(".navbar-upper .collapse")) {
  bsCollapse.addEventListener("show.bs.collapse", event => {
    bsCollapse.parentNode.classList.add("collapse-show");
    if (window.BASELINES_APP.isDevelopment) {
      clog({ bsCollapse, event, msg: "COLLAPSE SHOW" });
    }
  });

  bsCollapse.addEventListener("hide.bs.collapse", event => {
    bsCollapse.parentNode.classList.remove("collapse-show");
    if (window.BASELINES_APP.isDevelopment) {
      clog({ bsCollapse, event, msg: "COLLAPSE HIDE" });
    }
  });
}

if (window.BASELINES_APP.isDevelopment) {
  clog(window.BASELINES_APP);
}

export {
  setupGlobalOpts
};
