import Swiper from "swiper";
// eslint-disable-next-line import/extensions
import { Navigation as swNavigation, Pagination } from "swiper/modules";

window.BASELINES_APP.swipers = [];

// Now you can use Swiper

const swiper = new Swiper(".swiper", {
  loop: true,
  // Install modules
  modules: [swNavigation, Pagination],
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  on: {
    init: function(elem) {
      const nextButtons = document.querySelectorAll(".swiper-button-next");
      for (const btn of nextButtons) {
        btn.addEventListener("click", () => {
          const prevBtn = document.querySelector(`.swiper-button-prev[data-swiper-id="${btn.dataset.swiperId}"`);
          if (prevBtn) {
            prevBtn.style.opacity = "1";
            prevBtn.stsyle.visibility = "visible";
          }
        });
      }
    }
  },
  pagination: {
    clickable: true,
    el: ".swiper-pagination"
  },
  spaceBetween: 40,
  speed: 500
});

window.BASELINES_APP.swipers.push(swiper);
