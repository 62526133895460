import { animateForm } from "./_animate-labels.js";

// eslint-disable-next-line no-console
const clog = console.log;

const sleepUntil = async(f, timeoutMs) => new Promise((resolve, reject) => {
  const timeWas = new Date();
  const wait = setInterval(() => {
    if (f()) {
      if (window.BASELINES_APP.isDevelopment) {
        clog("resolved after", Date.now() - timeWas, "ms");
      }

      clearInterval(wait);
      resolve();
    } else if (Date.now() - timeWas > timeoutMs) { // Timeout
      clearInterval(wait);
      reject(new Error(`rejected after: ${Date.now() - timeWas} ms`));
    }
  }, 20);
});

if (document.querySelector("[id^=gform]")) {
  try {
    sleepUntil(() => window?.gform?.domLoaded === true, 30_000)
      .then(() => {
        return animateForm();
      }).catch(error => { clog(error); });
  } catch (error) {
    if (window?.BASELINES_APP?.isDevelopment) {
      clog({ error, msg: "Error occured on sleepUntil ( window?.gform?.domLoaded === true )" });
    }
  }
}
