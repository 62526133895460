const animateForm = () => {
  const formInputsSelector = ".gfield.animate-label [name^=\"input_\"]";

  // Find all input fields.
  const formInputs = document.querySelectorAll(formInputsSelector);

  for (const input of formInputs) {
    if (input.value !== "") {
      // Continue if the value of an input field is not empty.
      const formLabel = [...input.parentNode.parentNode.children]
        .find(item => item.classList.contains("gfield_label"));

      if (formLabel) {
        formLabel.classList.add("gfield_label--shift");
      }
    }

    input.addEventListener("focus", () => {
      document.querySelector(`label[for="${input.id}"]`)
        ?.classList.add("gfield_label--shift");
    });

    input.addEventListener("blur", () => {
      if (!input.value?.trim()?.length > 0) {
        document.querySelector(`label[for="${input.id}"]`)
          ?.classList.remove("gfield_label--shift");
      }
    });
  }
};

export {
  animateForm
};

