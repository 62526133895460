import "datatables.net-responsive-bs5";

import DataTable from "datatables.net-bs5";

const datatablesElems = document.querySelectorAll(".js-datatable");

if (datatablesElems) {
  const datatablesSelect = document.querySelector("#parent_cats_template")?.content.children[0];

  window.DataTable = DataTable;

  DataTable.ext.type.order["date-uk-pre"] = function(a) {
    // eslint-disable-next-line eqeqeq
    if (a == null || a == "") {
      return 0;
    }

    const ukDatea = a.split("/");
    return (ukDatea[2] + ukDatea[1] + ukDatea[0]) * 1;
  };

  DataTable.ext.type.order["date-uk-asc"] = function(a, b) {
    // eslint-disable-next-line no-nested-ternary
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
  };

  DataTable.ext.type.order["date-uk-desc"] = function(a, b) {
    // eslint-disable-next-line no-nested-ternary
    return ((a < b) ? 1 : ((a > b) ? -1 : 0));
  };

  window.BASELINES_APP.datatables = [];

  for (const table of datatablesElems) {
    window.BASELINES_APP.datatables.push(
      new DataTable(table, {
        columnDefs: [
          { responsivePriority: 0, targets: 0, type: "date-uk" },
          { responsivePriority: -1, targets: 1 },
          { responsivePriority: 3, targets: 2 }
        ],
        initComplete: function(thisTable) {
          const datatablesSelect = document.querySelector("#parent_cats");
          if (datatablesSelect) {
            datatablesSelect.addEventListener("change", () => {
              this.api().column(2).search(datatablesSelect.value, {
                exact: false
              }).draw();
            });
          }
        },
        layout: {
          bottom2Start: {
            pageLength: {
              menu: [15, 25, 50, 100, 200]
            }
          },
          bottomEnd: {
            paging: {
              type: "full_numbers"
            }
          },
          bottomStart: "info",
          topStart: datatablesSelect || null
        },
        order: [[0, "desc"]],
        pageLength: 15,
        processing: true,
        // responsive: true,
        responsive: {
          details: {
            renderer: DataTable.Responsive.renderer.tableAll({
              tableClass: "table table-sm"
            })
          }
        },
        serverSide: false,
        stateSave: false
      })
    );
  }
}
