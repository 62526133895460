/* eslint-disable no-mixed-operators */

import { Collapse } from "bootstrap";

const easeInBack = function(beginningValue, endValue, durationInFrames, framesElapsed, delayInFrames=0) {
  let t = framesElapsed - delayInFrames < 0 ? 0 : framesElapsed;  // time since start (as frames elapsed)
  const b = beginningValue; // beginning value
  const c = endValue - beginningValue; // change in value overall
  const d = durationInFrames; // duration (in frames) overall
  const s = 1.701_58;
  return c * (t/=d) * t * ((s+1) * t - s) + b;
};

const easeOutBack = function(beginningValue, endValue, durationInFrames, framesElapsed, delayInFrames=0) {
  let t = framesElapsed - delayInFrames < 0 ? 0 : framesElapsed;  // time since start (as frames elapsed)
  const b = beginningValue; // beginning value
  const c = endValue - beginningValue; // change in value overall
  const d = durationInFrames; // duration (in frames) overall
  const s = 1.701_58;
  return c * ((t=t/d-1) * t * ((s+1) * t + s) + 1) + b;
};

// Initiation Variables
const button = document.querySelector("#main-nav-toggler");
const topLine = document.querySelector("#main-nav-toggler-top-line");
const middleLine = document.querySelector("#main-nav-toggler-middle-line");
const bottomLine = document.querySelector("#main-nav-toggler-bottom-line");
let state = "menu";  // can be "menu" or "arrow"
let topLineY;
let bottomLineY;
let topLeftY;
let topRightY;
let bottomLeftY;
let bottomRightY;
let topLeftX;
let topRightX;
let bottomLeftX;
let bottomRightX;

// Animation Variables
const segmentDuration = 15;
const menuDisappearDurationInFrames = segmentDuration;
const arrowAppearDurationInFrames = segmentDuration;
const arrowDisappearDurationInFrames = segmentDuration;
const menuAppearDurationInFrames = segmentDuration;
let menuDisappearComplete = false;
let arrowAppearComplete = false;
let arrowDisappearComplete = false;
let menuAppearComplete = false;
let currentFrame = 1;

// Menu Disappear
function menuDisappearAnimation() {
  currentFrame++;
  if (currentFrame <= menuDisappearDurationInFrames) {
    window.requestAnimationFrame(()=> {
      // top line
      topLineY = easeInBack(37, 50, menuDisappearDurationInFrames, currentFrame);
      topLine.setAttribute("d", `M30,${topLineY} L70,${topLineY}`);
      // bottom line
      bottomLineY = easeInBack(63, 50, menuDisappearDurationInFrames, currentFrame);
      bottomLine.setAttribute("d", `M30,${bottomLineY} L70,${bottomLineY}`);
      // recursion
      menuDisappearAnimation();
    });
  } else {
    middleLine.style.opacity = "0";
    currentFrame = 1;
    menuDisappearComplete = true;
    openMenuAnimation();
  }
}

// Cross Appear
function arrowAppearAnimation() {
  currentFrame++;
  if (currentFrame <= arrowAppearDurationInFrames) {
    window.requestAnimationFrame(()=> {
      // top line
      topLeftX = easeOutBack(30, 35, arrowAppearDurationInFrames, currentFrame);
      topLeftY = easeOutBack(50, 35, arrowAppearDurationInFrames, currentFrame);
      bottomRightX = easeOutBack(70, 65, arrowAppearDurationInFrames, currentFrame);
      bottomRightY = easeOutBack(50, 65, arrowAppearDurationInFrames, currentFrame);
      topLine.setAttribute("d", `M${  topLeftX  },${  topLeftY  } L${  bottomRightX  },${  bottomRightY}`);
      // bottom line
      bottomLeftX = easeOutBack(30, 35, arrowAppearDurationInFrames, currentFrame);
      bottomLeftY = easeOutBack(50, 65, arrowAppearDurationInFrames, currentFrame);
      topRightX = easeOutBack(70, 65, arrowAppearDurationInFrames, currentFrame);
      topRightY = easeOutBack(50, 35, arrowAppearDurationInFrames, currentFrame);
      bottomLine.setAttribute("d", `M${  bottomLeftX  },${  bottomLeftY  } L${  topRightX  },${  topRightY}`);
      // recursion
      arrowAppearAnimation();
    });
  } else {
    currentFrame = 1;
    arrowAppearComplete = true;
    openMenuAnimation();
  }
}

// Combined Open Menu Animation
function openMenuAnimation() {
  if (!menuDisappearComplete) {
    menuDisappearAnimation();

  } else if (!arrowAppearComplete) {
    arrowAppearAnimation();
  }
}

// Cross Disappear
function arrowDisappearAnimation() {
  currentFrame++;
  if (currentFrame <= arrowDisappearDurationInFrames) {
    window.requestAnimationFrame(() => {
      // Top line
      topLeftX = easeInBack(35, 30, arrowDisappearDurationInFrames, currentFrame);
      topLeftY = easeInBack(35, 50, arrowDisappearDurationInFrames, currentFrame);
      bottomRightX = easeInBack(65, 70, arrowDisappearDurationInFrames, currentFrame);
      bottomRightY = easeInBack(65, 50, arrowDisappearDurationInFrames, currentFrame);
      topLine.setAttribute("d", `M${  topLeftX  },${  topLeftY  } L${  bottomRightX  },${  bottomRightY}`);
      // Bottom line
      bottomLeftX = easeInBack(35, 30, arrowDisappearDurationInFrames, currentFrame);
      bottomLeftY = easeInBack(65, 50, arrowDisappearDurationInFrames, currentFrame);
      topRightX = easeInBack(65, 70, arrowDisappearDurationInFrames, currentFrame);
      topRightY = easeInBack(35, 50, arrowDisappearDurationInFrames, currentFrame);
      bottomLine.setAttribute("d", `M${  bottomLeftX  },${  bottomLeftY  } L${  topRightX  },${  topRightY}`);
      // Recursion
      arrowDisappearAnimation();
    });
  } else {
    middleLine.style.opacity = "1";
    currentFrame = 1;
    arrowDisappearComplete = true;
    closeMenuAnimation();
  }
}

// Menu Appear
function menuAppearAnimation() {
  currentFrame++;
  if (currentFrame <= menuAppearDurationInFrames) {
    window.requestAnimationFrame(()=> {
      // Top line
      topLineY = easeOutBack(50, 37, menuDisappearDurationInFrames, currentFrame);
      topLine.setAttribute("d", `M30,${topLineY} L70,${topLineY}`);
      // Bottom line
      bottomLineY = easeOutBack(50, 63, menuDisappearDurationInFrames, currentFrame);
      bottomLine.setAttribute("d", `M30,${bottomLineY} L70,${bottomLineY}`);
      // Recursion
      menuAppearAnimation();
    });
  } else {
    currentFrame = 1;
    menuAppearComplete = true;
    closeMenuAnimation();
  }
}

// Close Menu Animation
function closeMenuAnimation() {
  if (!arrowDisappearComplete) {
    arrowDisappearAnimation();
  } else if (!menuAppearComplete) {
    menuAppearAnimation();
  }
}

function setupBackdrop() {
  if (!document.querySelector("#backdrop")) {
    const backdrop = document.createElement("div");
    backdrop.id = "backdrop";
    document.body.append(backdrop);
    backdrop.addEventListener("click", () => {
      button.click();
      backdrop.remove();
    });
  }
}

// Events
button.addEventListener("click", () => {
  button.disabled = true;
  if (state === "menu") {
    openMenuAnimation();
    setupBackdrop();
    state = "arrow";
    arrowDisappearComplete = false;
    menuAppearComplete = false;
  } else if (state === "arrow") {
    if (document.querySelector("#backdrop")) {
      document.querySelector("#backdrop").remove();
    }

    closeMenuAnimation();
    state = "menu";
    menuDisappearComplete = false;
    arrowAppearComplete = false;
  }

  // Block spamming the button
  setTimeout(() => {
    button.disabled = false;
  }, 500);
});
